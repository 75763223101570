import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import background from "../../images/switchboards/banner.jpg"
import HowWeCanHelp from "../../components/HowWeCanHelp";
import {Gallery} from "../../components/Gallery";
import img1 from "../../images/switchboards/1.jpg";
import img2 from "../../images/switchboards/2.jpg";
import img3 from "../../images/switchboards/3.jpg";
import img4 from "../../images/switchboards/4.jpg";
import img5 from "../../images/switchboards/5.jpg";
import img6 from "../../images/switchboards/6.jpg";
import img7 from "../../images/switchboards/7.jpg";
import img8 from "../../images/switchboards/8.jpg";
import img9 from "../../images/switchboards/9.jpg";
import img10 from "../../images/switchboards/10.jpg";
import img11 from "../../images/switchboards/11.jpg";
import img12 from "../../images/switchboards/12.jpg";
import img13 from "../../images/switchboards/13.jpg";

const images = [
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    },
    {
        original: img4,
    },
    {
        original: img5,
    },
    {
        original: img6,
    },
    {
        original: img7,
    },
    {
        original: img8,
    },
    {
        original: img9,
    },
    {
        original: img10,
    },
    {
        original: img11,
    },
    {
        original: img12,
    },
    {
        original: img13,
    },
];
const Switchboards = () => {
    return (
        <PageLayout
            noButton
            image={background}
            title={"Switchboards"}
            navSize={"h-96"}
            titleStyles={"xl:mt-12 lg:mt-20 md:mt-20 sm:mt-16 mt-24"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Switchboard Services
                </title>
                <meta
                    name="description"
                    content="Field transfer systems and automated transfer systems to give reliable power generation across the remote Northern Territory."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-lightgray"}>
                <p
                    className={
                        "text-center font-exo2 leading-8 text-xl py-5"
                    }
                >
                    E-P-G Solutions is highly experienced in the design, building, installation and commissioning of switchboards for power stations, commercial and industrial installations. The switchboards we design allow processes to be automated for the safety of essential services, staff and the community as well as the reliability of the power supply.
                </p>
            </SectionLayout>
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"bg-epg-white pt-14"}>
                    <p className={"leading-7 font-exo2 lg:leading-8 lg:text-lg text-epg-black"}>
                        We have designed a range of switchboards and control systems including
                        field transfer systems and automated fuel transfer systems that meet
                        the needs of remote power generation and water. These include:
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        Our services include:
                    </p>
                    <ul className={"list-disc p-4 lg:leading-8 lg:text-lg leading-8 font-exo2 text-epg-black"}>
                        <li>DT240 (Day Tank 240Volt AC Control)</li>
                        <li>
                            DT240e (Day Tank 240Volt AC Control – Rain hood and Sunshades)
                        </li>
                        <li>FTS12 (12Volt DC Manual/Auto Fuel Transfer System)</li>
                        <li>FTS415 (415Volt AC Manual/Auto Fuel Transfer System)</li>
                        <li>
                            ATS415/125 (4 Pole Manual/Auto 125 Amp Transfer System with Surge
                            Protection)
                        </li>
                        <li>
                            ATS415/63 (4 Pole Manual/Auto 63 Amp Transfer System with Surge
                            Protection)
                        </li>
                        <li>
                            ATS230/63 (2 Pole Manual/Auto 63 Amp Transfer System with Surge
                            Protection)
                        </li>
                        <li>
                            ATS230/125 (2 Pole Manual/Auto 125 Amp Transfer System with Surge
                            Protection)
                        </li>
                        <li>
                            ATS415/400 (4 Pole Manual/Auto 400 Amp Transfer System with Surge
                            Protection)
                        </li>
                    </ul>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        As well as design, we are experienced in switchboard repairs and
                        maintenance utilising techniques such as thermal imaging to identify
                        potential problems before they strike.
                    </p>
                </div>
                <div className={"bg-epg-white pt-5"}>
                    <Gallery images={images} additionalClass={{ height: 200 }} />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default Switchboards;
